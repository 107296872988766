import Title from "../../components/title/title";
import List from "../../components/list/list";
import Select from "../../components/select/select";
import Promo from "../../components/promo/promo";
import Casa from '../../components/casa/casa'
import Anni from '../../components/anni/anni'
import Tim from "../../img/logo/tim.png";
import Form from "../../components/form/form";
// import Spinner from "./components/spinner/spinner"
import { createContext, useContext, useState } from "react";
import { GlobalContext } from "../../contexts/globalContext";

function TimMobile() {
  const [operator, setOperator] = useState();
  const [componentState, setComponentState] = useState("list");
  const [form, setForm] = useState();

  let component, title;

  switch (componentState) {
    case "list":
      title = <Title testo="SCOPRI LA PROMO RISERVATA A TE" />;
      component = <List subtitle="true" />;
      break;

    case "casa":
      title = <Title testo="Hai Tim Fibra o Voce a casa?🏠" />;
      component = <Casa />;
      break;

      case "unica":
      title =<Title testo="SE HAI GIA' TIM A CASA" />;
      component = <Promo op="unica"/>;
      break;

      case "young":
      title =<Title testo="SE HAI MENO DI 25 ANNI" />;
      component = <Promo op="young"/>;
      break;

      case "anni":
      title = <Title testo="Hai meno di 25 anni?" />;
      component = <Anni /> ;
      break;

    case "promo":
      if (operator === "tim") {
        title = <></>;
      } else {
        title = <Title testo="IN ESCLUSIVA SOLO PER TE"/>;
      };
      component = <Promo op={operator} />;
      break;

    case "form":
      title = <Title testo="INSERISCI IL TUO NUMERO E OTTIENI LA PROMO 📞" />;
      component = <Form />;
      break;

    default:
      break;
  }

  return (
    <div>
    <div className="App">
      <div className="app-container">
        <div className="main-logo-container">
          <img src={Tim} alt="logo-tim" />
        </div>
        <GlobalContext.Provider
          value={{
            ContextOperator: operator,
            ContextSetOperator: setOperator,
            ContextState: componentState,
            ContextSetState: setComponentState,
          }} >
          {title}
          <br />
          {component}
        </GlobalContext.Provider>
      </div>
      
    </div>
    <footer style={{textAlign:'center'}}>
     <p>Bolletta.net è un brand E-leads</p>
      <a target='blank' href="https://e-leads.it/Privacy-Policy-Sito-web">Informativa Privacy</a>
    </footer>
    </div>
  );
}

export default TimMobile;
