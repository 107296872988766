
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Root from "./pages/root/root";
import TimMobile from './pages/tim-mobile/tim-mobile'
import TimMobile2 from './pages/tim-mobile-2/tim-mobile-2';
import ThankYou2 from "./pages/thank-you-2/thank-you-2";
import ThankYou from "./pages/thank-you/thank-you";
import Gas from "./pages/gas/gas";
import TimBusiness from "./pages/tim-business/tim-business";
import DemoEnergy from "./pages/demo-energy/demo-energy"
import CheTariffa from "./pages/chetariffa/chetariffa"

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Root />} />
            <Route path="thank-you" element={<ThankYou />} />
            <Route path="thank-you-2" element={<ThankYou2 />} />
            <Route path="tim-mobile" element={<TimMobile />} />
            <Route path="tim-mobile-2" element={<TimMobile2 />} />
            <Route path="luce-e-gas" element={<Gas />} />
            <Route path="tim-business" element={<TimBusiness />} />
            <Route path="demo-energy" element={<DemoEnergy />} />
            <Route path="chetariffa" element={<CheTariffa />} />
        </Routes>
    </BrowserRouter>
);
  
}

export default App;
