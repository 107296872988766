import { Link } from "react-router-dom";
import './root.css'

function Root() {
    return (
        <>
      
      {/* <Link to="tim-mobile"><h1>Tim Mobile</h1></Link>
      <Link to="tim-mobile-2"><h1>Tim Mobile 2</h1></Link>
      <Link to="luce-e-gas"><h1>Luce e Gas</h1></Link>
      <Link to="tim-business"><h1>Tim Business</h1></Link>
      <Link to="demo-energy"><h1>Demo Energy</h1></Link> */}
      </>
    );
  }
  
  export default Root;