import "./list.css";
import vodafone from "../../img/logo/vodafone.png";
import iliad from "../../img/logo/iliad.png";
import wind from "../../img/logo/wind.png";
import very from "../../img/logo/very.png";
import ho from "../../img/logo/ho.png";
import lyca from "../../img/logo/lyca.png";
import kena from "../../img/logo/kena.png";
import noverca from "../../img/logo/noverca.png";
import tim from '../../img/logo/tim-2.png'
import React, { useState, useContext } from "react";
import {
  GlobalContext
} from "../../contexts/globalContext";

function List(props) {
const { ContextSetOperator, ContextSetState } = useContext(GlobalContext);

  function handleClick(operatore) {
    ContextSetOperator(operatore);
    if (operatore === "nv" || operatore === "v1") {
      ContextSetState("casa")
    }else {
      ContextSetState("promo")
    }
    
  }

  return (

      <div className="list-container columns" >
        
        <div className="subtitle">Quale operatore hai adesso sul Cellulare? 📱</div>


        <div className="logo-container column" onClick={() => handleClick("tim")}>
          <img src={tim}></img>
        </div>
        
        <div className="logo-container column" onClick={() => handleClick("nv")}>
          <img src={vodafone}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("iliad")}>
          <img src={iliad}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("nv")}>
          <img src={wind}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("nv")}>
          <img src={very}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("v1")}>
          <img src={ho}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("v1")}>
          <img src={lyca}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("nv")}>
          <img src={kena}></img>
        </div>
        <div className="logo-container column" onClick={() => handleClick("nv")}>
          <img src={noverca}></img>
        </div>
        
        <div className="logo-container column" onClick={() => handleClick("v")}>
          <div className="altro">Altro</div>
        </div>

        
      </div>
      
  );
}

export default List;
