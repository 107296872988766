import './anni.css' 
import React, { useState, useContext } from "react";
import {
    GlobalContext
  } from "../../contexts/globalContext";

function Anni() {
    
    const {ContextSetState, ContextSetOperator } = useContext(GlobalContext);
    return(
    <div className="columns">
    <div className='column' onClick={() => ContextSetState("young")}>
      <h2>Si 👍</h2>
    </div>

    <div className='column' onClick={() => ContextSetState("promo")}>
      <h2>No ✋</h2>
    </div>
</div>
    )
}

export default Anni