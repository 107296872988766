
import "./thank-you.css";

function ThankYou() {
   
    window.gtag("event", "conversion", {
        send_to: "AW-10952985416/rbkoCKOaj9ADEMiW5eYo",
    });

    return (
        
        <div className="typ">
            <img height="1" width="1" style={{display:"none"}} src="https://www.facebook.com/tr?id=1094125090998070&ev=PageView&noscript=1" />
            <img height="1" width="1" style={{display:"none"}} src="https://www.facebook.com/tr?id=1094125090998070&ev=CompleteRegistration&noscript=1" />
            <h1 className="title">GRAZIE</h1>
            <p className="subtitle">Verrai contattato a breve!</p>
            <iframe src="https://trk.elds.it/success.php?offer_id=86" height="1" width="1" />
        </div>

        
    );
}

export default ThankYou;