import './select.css';
import React, { useState } from 'react';
import List from '../list/list.js';


function  Select(){
  let style = {transform: 'rotate(0deg)', transition: 'transform 200ms ease',}
  const [rotate, setRotate] = useState(false);
  const [visible, setVisible] = useState(false);

  function handleClick() {
    setVisible(!visible);
    setRotate(!rotate);
  }

  if (rotate){
    style = {
      transform: 'rotate(180deg)', 
      transition: 'transform 200ms ease',
     }
  }

return(
  <div>
  <div className="op-select" onClick={handleClick}>
    <p>Seleziona il tuo operatore</p>
    <svg id="arrow" style={style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47727 0 0 4.47727 0 10C0 15.5227 4.47727 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47727 15.5227 0 10 0ZM7.00636 7.99364C6.83491 7.82804 6.60527 7.73641 6.36691 7.73848C6.12855 7.74055 5.90054 7.83616 5.73198 8.00471C5.56343 8.17326 5.46782 8.40127 5.46575 8.63964C5.46368 8.878 5.55531 9.10763 5.72091 9.27909L9.35727 12.9155C9.52775 13.0859 9.75894 13.1816 10 13.1816C10.2411 13.1816 10.4722 13.0859 10.6427 12.9155L14.2791 9.27909C14.4447 9.10763 14.5363 8.878 14.5343 8.63964C14.5322 8.40127 14.4366 8.17326 14.268 8.00471C14.0995 7.83616 13.8715 7.74055 13.6331 7.73848C13.3947 7.73641 13.1651 7.82804 12.9936 7.99364L10 10.9873L7.00636 7.99364Z" fill="#F8F8F8"/></svg>
  </div>
  
  { visible ? <List /> : null }
  </div>
  
)

}

export default Select;