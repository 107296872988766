import './chetariffa.css'
import logo from './../../img/gas/logo-white.svg'
import { GlobalContext } from "../../contexts/globalContext";
import { createContext, useContext, useState } from "react";
import Servizi from "../../components/servizi/servizi"
import Suppliers from "../../components/suppliers/suppliers"
import Day from "../../components/day/day"
import People from "../../components/people/people"
import CheTariffaForm from "../../components/chetariffa-form/chetariffa-form"


function CheTariffa(){
    const [componentState, setComponentState] = useState("step1");
    let title, component, subtitle;


    switch (componentState) {
        case "step1":
          title = <div><h3>Lo sapevi che ogni anno</h3><h1>Puoi risparmiare fino a <span className="text-blue">317€</span>?</h1></div>;
          subtitle = <h3>Trova la fornitura più conveniente per te in base alle tue <span className="text-blue">esigenze</span> e <span className="text-blue">abitudini</span></h3>;
          component = <div className='button-1' onClick={() => setComponentState("step2") }>INIZIA A RISPARMIARE</div>;
          break;

          case "step2":
          title = <h1>A quale servizio sei interessato?</h1>;
          subtitle = <></>
          component = <Servizi />;
          break;

          case "step3":
          title = <h1>Qual é il tuo fornitore attuale?</h1>;
          subtitle = <></>
          component = <Suppliers />;
          break;
          

          case "step4":
          title = <h1>in quale orario consumi di più?</h1>;
          subtitle = <></>
          component = <Day />;
          break;


          case "step5":
          title = <h1>utilizzi il condizionatore?</h1>;
          subtitle = <></>
          component = <div className="day-parts">
          <div className="day-part" onClick={() => setComponentState("step6") }>
            <div className="day-text">Si</div>
          </div>
    
          <div className="day-part" onClick={() => setComponentState("step6") } >
            <div className="day-text">No</div>
          </div>
        </div>;
          break;

          case "step6":
          title = <h1>puoi risparmiare fino al: <br/> <span className='text-blue'>20% sulle utenze</span></h1>;
          subtitle = <h3>Parla <span className='text-blue'>gratis</span> e <span className='text-blue'>senza impegno</span> con un operatore che ti illustrerà come attivare la promozione.<br/><br/> INIZIA A <span className='text-blue'>RISPARMIARE</span>!</h3>
          component = <CheTariffaForm />;
          break;

        default:
          break;
      }

 return(
    <div>
    <div className='logo-bolletta'><img src={logo} alt="logo"/></div>
    <div className='main'>
     <div className='main-container'>

     <GlobalContext.Provider
          value={{
            ContextState: componentState,
            ContextSetState: setComponentState,
          }} >
          {title}
          {subtitle}
          {component}
        </GlobalContext.Provider>
     

        

        

     </div>
     
    </div>
    <footer style={{textAlign:'center', color:'grey'}}>
      Bolletta.net è un brand E-leads <br/>
      <a style={{color:'orange'}}target="blank" href="https://e-leads.it/Privacy-Policy-Sito-web">Informativa Privacy</a>
     </footer>
    </div>
 )
}

export default CheTariffa