import "./tim-mobile.css";
import Title from "../../components/title/title";
import Select from "../../components/select/select";
import Promo from "../../components/promo/promo";
import Tim from "../../img/logo/tim.png";
import Form from "../../components/form/form"
// import Spinner from "./components/spinner/spinner"
import { createContext, useContext, useState } from "react";
import { GlobalContext } from "../../contexts/globalContext";

function TimMobile() {
  const [operator, setOperator] = useState()
  const [form, setForm] = useState()
  




  let title;

  if(!operator){
    title = <Title testo="Scopri la promozione riservata a te" />
  } else if( operator === "tim"){
    title = <></>
  } else {
    title = <Title testo="OTTIENI ORA" />
  }

  if (form) {
   title = <Title testo="INSERISCI IL TUO NUMERO E OTTIENI LA PROMO" />
  }

  return (
    <div className="App">
      <div className="app-container">
        <div className="main-logo-container">
          <img src={Tim} alt="logo-tim" />
        </div>
        <GlobalContext.Provider value={ {ContextOperator: operator, ContextSetOperator: setOperator, ContextForm: form, ContextSetForm: setForm}}>
          { title }
          { !operator ? <Select /> : null }
          { operator ? <Promo op={operator}/> : null }
          { form ? <Form/> : null }
        </GlobalContext.Provider>
        
      </div>
    </div>
  );
}

export default TimMobile;
