import './casa.css' 
import React, { useState, useContext } from "react";
import {
    GlobalContext
  } from "../../contexts/globalContext";
  
function Casa(){
    const {ContextSetState, ContextOperator } = useContext(GlobalContext);

    if(ContextOperator === "nv"){
        var nextStep = "anni"
    }else{
        var nextStep = "promo"
    }


  
    return(
      
        <div className="columns">
              <div className='column' onClick={() => ContextSetState("unica")}>
                <h2>Si 💖</h2>
              </div>

              <div className='column' onClick={() => ContextSetState(nextStep)}>
                <h2>No ⛔</h2>
              </div>
        </div>
        
    )
}

export default Casa