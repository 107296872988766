import './promo.css';
import Form from "../form/form";
import React, { useContext, useState } from 'react';

import {
    GlobalContext
  } from "../../contexts/globalContext";
  

function Promo(props) {
    const { ContextSetState } = useContext(GlobalContext);
  const [hide, setHIde] = useState(false);

    var containerStyle = {
        border: ' border: 2px solid #fff'
    }

    if (props.op === "tim") {
        containerStyle = {
            border: 'none'
        }
    }

    function handleClick(form) {
        setHIde(!hide);
        ContextSetState("form");
      }
      if (hide) {
        containerStyle = {
          display: "none",
        };
      }
   

    switch (props.op) {
        case "nv":
            var promoName = <h2>TIM 5G Power SMART</h2>
            var promoDescr = <div className='promo-descr'>Fino a 50 Giga in 5G, Minuti e SMS illimitati</div>
            var promoList = <ul>
                            <li>Navigazione Sicura</li> 
                            <li>Navigazione Prioritaria fino a 2Gbps</li>
                            <li>100GB di Google One per 3 mesi</li>
                        </ul>
             var promoPrice = <div className='price'>14,99€<span className='month'>/ mese</span></div>
             var promoBadge = <div className='badge'>ONLINE 1° MESE E ATTIVAZIONE GRATUITI</div>
             var form = <Form/>
             
            break;

            case "iliad":
            var promoName = <h2>TIM Wonder SIX</h2>
            var promoDescr = <div className='promo-descr'>Fai il pieno di Giga e parla in libertà.
            </div>
            var promoList = <ul>
                            <li>100 GIGA</li>
                            <li>Minuti e SMS illimitati</li> 
                            <li>Giga illimitati se hai TIM a casa</li>
                        
                        </ul>
             var promoPrice = <div className='price'>9,99€<span className='month'>/ mese</span></div>
             var promoBadge = <div className='badge'>ONLINE 1° MESE E ATTIVAZIONE GRATUITI</div>
             var form = <Form/>
             
            break;

        case "v1":
            var promoName = <h2>TIM Wonder</h2>
            var promoDescr = <div className='promo-descr'>Un'offerta esclusiva ad un prezzo speciale.
            </div>
            var promoList = <ul>
                            <li>50 GIGA</li>
                            <li>Minuti illimitati</li> 
                            <li>LoSai e ChiamaOra di TIM gratuiti</li>
                        
                        </ul>
                var promoPrice = <div className='price'>9,99€<span className='month'>/ mese</span></div>
                var promoBadge = <div className='badge'>ONLINE 1° MESE E ATTIVAZIONE GRATUITI</div>
                var form = <Form/>
            break;

            case "v":
                var promoName = <h2>TIM Wonder FIVE</h2>
                var promoDescr = <div className='promo-descr'>Un’offerta completa ad un prezzo esclusivo.
                </div>
                var promoList = <ul>
                                <li>70 GIGA</li>
                                <li>Minuti e SMS Illimitati</li> 
                                <li>Giga illimitati se hai TIM a casa</li>
                            
                            </ul>
                    var promoPrice = <div className='price'>7,99€<span className='month'>/ mese</span></div>
                    var promoBadge = <div className='badge'>ONLINE 1° MESE E ATTIVAZIONE GRATUITI</div>
                    var form = <Form/>
                break;

            case "tim":
                var promoName = <div className='alert'>CI DISPIACE, L'OFFERTA E' RISERVATA AI NUOVI CLIENTI</div>
                var promoDescr = <div hidden></div>
                    var promoList = <div hidden></div>
                    var promoPrice = <div hidden></div>
                    var promoBadge = <div hidden></div>
                    var form= <div hidden></div>
                    
                break;
            
                case "unica":
                    var promoName = <h2>TIM PREMIUM FAMIGLIA</h2>
                    var promoDescr = <div className='promo-descr'>Se hai TIM a casa, scegli TIM anche sul mobile. Per te:
                    </div>
                    var promoList = <ul>
                                    <li>Giga Illimitati con TIM Unica</li>
                                    <li>Minuti ed SMS Illimitati</li>
                                    <li>LoSai e ChiamaOra di TIM incluso</li> 
                                    
                                </ul>
                        var promoPrice = <div className='price'>9,99€<span className='month'>/ mese</span></div>
                        var promoBadge = <div className='badge'>ONLINE 1° MESE E ATTIVAZIONE GRATUITI</div>
                        var form = <Form/>
                        
                    break;

                    case "young":
                    var promoName = <h2>TIM YOUNG</h2>
                    var promoDescr = <div className='promo-descr'>Divertiti con la nuova promozione riservata agli Under 25
                    </div>
                    var promoList = <ul>
                                    <li>Naviga con il 5G di TIM.</li>
                                    <li>Fino a 100 giga</li> 
                                    <li>Minuti e SMS ILLIMITATI</li>
                                    <li>TIM MUSIC incluso</li>
                                </ul>
                        var promoPrice = <div className='price'>9,99€<span className='month'>/ mese</span></div>
                        var promoBadge = <div className='badge'>ONLINE 1° MESE E ATTIVAZIONE GRATUITI</div>
                        var form = <Form/>

                        
                       
                    break;

        default:
            break;
    }

return(
    <div className='promo-container' style={containerStyle}>
       {promoName}
       {promoDescr}
       {promoList}
       {promoBadge}
       {promoPrice}
       {form}
       
       
       
    </div>
)
}

export default Promo