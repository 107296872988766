import "./suppliers.css";
import enel from "../../img/gas/suppliers/enel.png";
import eni from "../../img/gas/suppliers/eni.png";
import acea from "../../img/gas/suppliers/acea.png";
import a2a from "../../img/gas/suppliers/a2a.png";
import edison from "../../img/gas/suppliers/edison.png";
import iren from "../../img/gas/suppliers/iren.png";
import optima from "../../img/gas/suppliers/optima.png";
import React, { useState, useContext } from "react";
import {
  GlobalContext
} from "../../contexts/globalContext";

function Suppliers(props) {
const { ContextSetOperator, ContextSetState } = useContext(GlobalContext);

  function handleClick() {
      ContextSetState("step4")
  }

  return (

      <div className="list-container columns" >


        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={enel}></img>
        </div>
        
        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={eni}></img>
        </div>
        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={acea}></img>
        </div>
        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={a2a}></img>
        </div>
        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={edison}></img>
        </div>
        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={iren}></img>
        </div>
        <div className="logo-container- column" onClick={() => handleClick()}>
          <img src={optima}></img>
        </div>
        
        <div className="logo-container- column" onClick={() => handleClick()}>
          <div className="altro">Altro</div>
        </div>

        
      </div>
      
  );
}

export default Suppliers;
