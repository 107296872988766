import "./form.css";

function Form() {
  window.addEventListener(
    "message",
    (event) => {
      console.log(event.data);

      if (event.origin + "/" == "https://eleads.makeyoudigit.com/") {
        if (!event.data.error) {
          window.location.replace(
            "./thank-you?sold=true&id=" +
              event.data.response.orderID 
          );
        } else {
          {
            window.location.replace("./thank-you");
          }
        }
      }
    },
    false
  );

  return (
    <div className="container">
    <div className="form-container">
      <iframe src="https://eleads.makeyoudigit.com/"></iframe>
      </div>
      <div>
        
      </div>
      <div className="label">
                                            Inserisci i tuoi dati, sarai contattato entro 48 ore da un consulente di TIM che ti aiutera' gratuitamente e senza impegno a scegliere la tariffa migliore per i nuovi clienti.
                                            Inserendo il mio numero di telefono, autorizzo TIM a contattarmi per ricevere assistenza e informazioni sull'acquisto delle offerte TIM. Dichiaro di
                                            aver preso visione dell' <a style={{color: '#ff0000', textDecoration: 'underline'}} target="_blank" href="https://www.tim.it/web-privacy-policy"><strong>Informativa privacy</strong></a>, di essere
                                            l'intestatario del numero fornito e di essere a conoscenza delle sanzioni previste dalla vigente normativa in caso di rilascio di dichiarazioni mendaci.
      
    </div>
    </div>
  );
}

export default Form;
