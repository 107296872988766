import "./demo-form-energy.css";

function GasForm() {
  window.el_p = {
    aff_id: "Wnd1kEiIxmaQmBqtcnQlxJpQveQ2",
    id: "6nJAYtLPVpW0JBSVYP9W",
    typ: "./thank-you-2",
  };
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  var u = "https://form.elds.it/eleads-lib.js";
  s.src = u;
  var e = document.getElementById("eleads-root");
  e.parentNode.insertBefore(s, e);
  return (
    <div className="gas-form-container">
        <div class="form-title text-center">LA CONSULENZA E’ GRATUITA</div>
      <div class="eleads-root"></div>
      <div class="eleads-form"></div>
    </div>
  );
}

export default GasForm;
