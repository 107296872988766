import "./tim-business.css";
import { createContext, useContext, useState } from "react";
import arrow from "./../../img/right-arrow.svg";
import speed from "./../../img/speed.svg";
import ip from "./../../img/ip.svg";
import wifi from "./../../img/wifi.svg";
import phone from "./../../img/phone.svg";
import user from "./../../img/user.svg";
import headset from "./../../img/headset.svg";
import Form from "../../components/form/form";

function TimBusiness() {
  const [componentState, setComponentState] = useState("A");
  let component = "";
  let heading = <div className="heading">Hai una Partita Iva?</div>;
  let subheading = (
    <div className="subheading">
      Passa alla FIBRA di TIM, puoi risparmiare fino a <b>2500</b>€ con i{" "}
      <b>Voucher Governativi</b>.
    </div>
  );
  let select = (
    <div className="select-voucher-desktop">
      <button
        className="option"
        autoFocus
        onClick={() => setComponentState("A")}
      >
        {" "}
        VOUCHER A<img src={arrow} />
      </button>
      <button className="option" onClick={() => setComponentState("B")}>
        VOUCHER B<img src={arrow} />
      </button>
      <button className="option" onClick={() => setComponentState("C")}>
        VOUCHER C<img src={arrow} />
      </button>
    </div>
  );
let moreText = "Piu dettagli";
  

  const [isShown, setIsShown] = useState(false);

    function handleClick()  {
    setIsShown(current => !current);

    }

  if (isShown) {
    moreText = "Meno dettagli"
  }


  switch (componentState) {
    case "A":
      component = (
        <div className="content-container">
          <div className="select-voucher">
            <button
              className="option"
              style={{ backgroundColor: "#fff", color: "#1b49ad" }}
              onClick={() => setComponentState("A")}
            >
              {" "}
              VOUCHER A
            </button>
            <button className="option" onClick={() => setComponentState("B")}>
              VOUCHER B
            </button>
            <button className="option" onClick={() => setComponentState("C")}>
              VOUCHER C
            </button>
          </div>
          <div className="promo-header" style={{ backgroundColor: "#0164F2" }}>
            <div className="voucher-title">VOUCHER A</div>
            <div className="voucher-text">Sconto 300€ (15€ per 20 mesi) </div>
            <div className="voucher-subtitle">+ sconto TIM</div>
            <div className="voucher-text">
              Sconto 118,8€ (9,90€ per 12 mesi)
            </div>
          </div>

          <div className="promo-content">
            <div className="promo-title" datatype="fr">
              Premium Business Voucher XDSL
            </div>
            <div className="promo-price">
              Anziché 19,90€ <br />
              <span className="big-price">10,00€</span>/ mese per 12 mesi <br />
              poi 19,90€ / mese e dal 21° mese 34,90€ / mese
            </div>
            <div className="unica">
              <div className="unica-header">
                PER I CLIENTI TIM UNICA BUSINESS
              </div>
              <div className="unica-content">
                <span className="big-price">5,00€</span>/ mese per 12 mesi{" "}
                <br />
                poi 19,90€ / mese e dal 21° mese 34,90€ / mese
              </div>
            </div>

            <div className="cta">
              Scopri di più sul voucher e su come ottenerlo, ti chiamiamo noi
              gratis e senza impegno
            </div>
            <div
              className="cta-button"
              onClick={() => setComponentState("form")}
            >
              SCOPRI DI PIU'
              <img src={arrow} />
            </div>

            <div className="more" onClick={() => handleClick()}>
                {moreText}
            </div>

            <div className="feature-list">
              <div className="feature">
                <img src={speed} />
                <div>
                  <b>Internet fino a 200 Mega</b>
                  <br />
                  in download e 20 Mega in Upload
                </div>
              </div>

              <div className="feature">
                <img src={ip} />
                <div>
                  <b>IP Statico</b>
                  <br />
                  Accedi ai tuoi dati anche da remoto
                </div>
              </div>

              <div className="feature">
                <img src={phone} />
                <div>
                  <b>1 Canale Voce</b>
                  <br />
                  Chiamate illimitate e servizi di reperibilità
                </div>
              </div>

              <div className="feature">
                <img src={wifi} />
                <div>
                  <b>Router Incluso</b>
                  <br />
                  Router Wi-Fi 6, in comodato d'uso gratuito
                </div>
              </div>

              <div className="feature">
                <img src={user} />
                <div>
                  <b>Installazione OnSite</b>
                  <br />
                  Installazione eseguita da un tecnico TIM
                </div>
              </div>
            </div>

            {isShown && <div className="feature-list-desktop">
              <div className="feature">
                <img src={speed} />
                <div>
                  <b>Internet fino a 200 Mega</b>
                  <br />
                  in download e 20 Mega in Upload
                </div>
              </div>

              <div className="feature">
                <img src={ip} />
                <div>
                  <b>IP Statico</b>
                  <br />
                  Accedi ai tuoi dati anche da remoto
                </div>
              </div>

              <div className="feature">
                <img src={phone} />
                <div>
                  <b>1 Canale Voce</b>
                  <br />
                  Chiamate illimitate e servizi di reperibilità
                </div>
              </div>

              <div className="feature">
                <img src={wifi} />
                <div>
                  <b>Router Incluso</b>
                  <br />
                  Router Wi-Fi 6, in comodato d'uso gratuito
                </div>
              </div>

              <div className="feature">
                <img src={user} />
                <div>
                  <b>Installazione OnSite</b>
                  <br />
                  Installazione eseguita da un tecnico TIM
                </div>
              </div>
            </div>}
          </div>
        </div>
      );
      break;

    case "B":
      component = (
        <div className="content-container">
          <div className="select-voucher">
            <button className="option" onClick={() => setComponentState("A")}>
              {" "}
              VOUCHER A
            </button>
            <button
              className="option"
              style={{ backgroundColor: "#fff", color: "#1b49ad" }}
              onClick={() => setComponentState("B")}
            >
              VOUCHER B
            </button>
            <button className="option" onClick={() => setComponentState("C")}>
              VOUCHER C
            </button>
          </div>
          <div className="promo-header" style={{ backgroundColor: "#007C77" }}>
            <div className="voucher-title">VOUCHER B</div>
            <div className="voucher-text">Sconto 500€ (20€ per 25 mesi) </div>
            <div className="voucher-subtitle">+ sconto TIM</div>
            <div className="voucher-text">
              Sconto 118,8€ (9,90€ per 12 mesi)
            </div>
          </div>

          <div className="promo-content">
            <div className="promo-title" datatype="f">
              Premium Business Voucher Fibra
            </div>
            <div className="promo-price">
              Anziché 14,90€ <br />
              <span className="big-price">5,00€</span>/ mese per 12 mesi <br />
              poi 14,90€ / mese e dal 26° mese 29,90€ / mese
            </div>
            <div className="unica">
              <div className="unica-header">
                PER I CLIENTI TIM UNICA BUSINESS
              </div>
              <div className="unica-content">
                <span className="big-price">0,00€</span>/ mese per 12 mesi{" "}
                <br />
                poi 9,90€ / mese e dal 21° mese 29,90€ / mese
              </div>
            </div>

            <div className="cta">
              Scopri di più sul voucher e su come ottenerlo, ti chiamiamo noi
              gratis e senza impegno
            </div>
            <div
              className="cta-button"
              onClick={() => setComponentState("form")}
            >
              SCOPRI DI PIU'
              <img src={arrow} />
            </div>

            <div className="more" onClick={() => handleClick()}>
                {moreText}
            </div>

            <div className="feature-list">
              <div className="feature">
                <img src={speed} />
                <div>
                  <b>Internet fino a 1 Giga</b>
                  <br />
                  in download e 20 Mega in Upload
                </div>
              </div>

              <div className="feature">
                <img src={ip} />
                <div>
                  <b>IP Statico</b>
                  <br />
                  Accedi ai tuoi dati anche da remoto
                </div>
              </div>

              <div className="feature">
                <img src={phone} />
                <div>
                  <b>1 Canale Voce</b>
                  <br />
                  Chiamate illimitate e servizi di reperibilità
                </div>
              </div>

              <div className="feature">
                <img src={wifi} />
                <div>
                  <b>Router Incluso</b>
                  <br />
                  Router Wi-Fi 6, in comodato d'uso gratuito
                </div>
              </div>

              <div className="feature">
                <img src={user} />
                <div>
                  <b>Installazione OnSite</b>
                  <br />
                  Installazione eseguita da un tecnico TIM
                </div>
              </div>
            </div>

            {isShown && <div className="feature-list-desktop">
              <div className="feature">
                <img src={speed} />
                <div>
                  <b>Internet fino a 1 Giga</b>
                  <br />
                  in download e 20 Mega in Upload
                </div>
              </div>

              <div className="feature">
                <img src={ip} />
                <div>
                  <b>IP Statico</b>
                  <br />
                  Accedi ai tuoi dati anche da remoto
                </div>
              </div>

              <div className="feature">
                <img src={phone} />
                <div>
                  <b>1 Canale Voce</b>
                  <br />
                  Chiamate illimitate e servizi di reperibilità
                </div>
              </div>

              <div className="feature">
                <img src={wifi} />
                <div>
                  <b>Router Incluso</b>
                  <br />
                  Router Wi-Fi 6, in comodato d'uso gratuito
                </div>
              </div>

              <div className="feature">
                <img src={user} />
                <div>
                  <b>Installazione OnSite</b>
                  <br />
                  Installazione eseguita da un tecnico TIM
                </div>
              </div>
            </div>}

            
          </div>
        </div>
      );
      break;

    case "C":
      component = (
        <div className="content-container">
          <div className="select-voucher">
            <button className="option" onClick={() => setComponentState("A")}>
              {" "}
              VOUCHER A
            </button>
            <button className="option" onClick={() => setComponentState("B")}>
              VOUCHER B
            </button>
            <button
              className="option"
              style={{ backgroundColor: "#fff", color: "#1b49ad" }}
              onClick={() => setComponentState("C")}
            >
              VOUCHER C
            </button>
          </div>
          <div className="promo-header" style={{ backgroundColor: "#001136" }}>
            <div className="voucher-title">VOUCHER C</div>
            <div className="voucher-text">Sconto 2000€ (55€ per 25 mesi) </div>
            <div className="voucher-subtitle">+ sconto TIM</div>
            <div className="voucher-text">
              Sconto 52,08€ (4,34€ per 12 mesi)
            </div>
          </div>

          <div className="promo-content">
            <div className="promo-title" datatype="f">
              Suprema Premium Business Voucher Fibra
            </div>
            <div className="promo-price">
              Anziché 9,34€ <br />
              <span className="big-price">5,00€</span>/ mese per 12 mesi <br />
              poi 9,34€ / mese e dal 37° mese 64,90€ / mese
            </div>
            <div className="unica">
              <div className="unica-header">
                PER I CLIENTI TIM UNICA BUSINESS
              </div>
              <div className="unica-content">
                <span className="big-price">0,00€</span>/ mese per 12 mesi{" "}
                <br />
                poi 4,34€ / mese e dal 37° mese 59,90€ / mese
              </div>
            </div>

            <div className="cta">
              Scopri di più sul voucher e su come ottenerlo, ti chiamiamo noi
              gratis e senza impegno
            </div>
            <div
              className="cta-button"
              onClick={() => setComponentState("form")}
            >
              SCOPRI DI PIU'
              <img src={arrow} />
            </div>
            
            <div className="more" onClick={() => handleClick()}>
                {moreText}
            </div>

            <div className="feature-list">
              <div className="feature">
                <img src={speed} />
                <div>
                  <b>Internet fino a 2.5 Giga</b>
                  <br />
                  in download e 1 Giga in Upload
                </div>
              </div>

              <div className="feature">
                <img src={ip} />
                <div>
                  <b>IP Statico</b>
                  <br />
                  Accedi ai tuoi dati anche da remoto
                </div>
              </div>

              <div className="feature">
                <img src={phone} />
                <div>
                  <b>1 Canale Voce</b>
                  <br />
                  Chiamate Illimitate, servizi di reperibilità e chiamate
                  illimitate verso FIssi ITZ zona1
                </div>
              </div>

              <div className="feature">
                <img src={wifi} />
                <div>
                  <b>Router Incluso</b>
                  <br />
                  Router Wi-Fi 6, in comodato d'uso gratuito
                </div>
              </div>

              <div className="feature">
                <img src={user} />
                <div>
                  <b>Installazione OnSite</b>
                  <br />
                  Installazione eseguita da un tecnico TIM
                </div>
              </div>

              <div className="feature">
                <img src={headset} />
                <div>
                  <b>Assistenza Personale</b>
                  <br />
                  Risoluzione guasto linea in un giorno e team dedicato per
                  assistenza commerciale
                </div>
              </div>
            </div>

            {isShown &&  <div className="feature-list-desktop">
              <div className="feature">
                <img src={speed} />
                <div>
                  <b>Internet fino a 2.5 Giga</b>
                  <br />
                  in download e 1 Giga in Upload
                </div>
              </div>

              <div className="feature">
                <img src={ip} />
                <div>
                  <b>IP Statico</b>
                  <br />
                  Accedi ai tuoi dati anche da remoto
                </div>
              </div>

              <div className="feature">
                <img src={phone} />
                <div>
                  <b>1 Canale Voce</b>
                  <br />
                  Chiamate Illimitate, servizi di reperibilità e chiamate
                  illimitate verso FIssi ITZ zona1
                </div>
              </div>

              <div className="feature">
                <img src={wifi} />
                <div>
                  <b>Router Incluso</b>
                  <br />
                  Router Wi-Fi 6, in comodato d'uso gratuito
                </div>
              </div>

              <div className="feature">
                <img src={user} />
                <div>
                  <b>Installazione OnSite</b>
                  <br />
                  Installazione eseguita da un tecnico TIM
                </div>
              </div>

              <div className="feature">
                <img src={headset} />
                <div>
                  <b>Assistenza Personale</b>
                  <br />
                  Risoluzione guasto linea in un giorno e team dedicato per
                  assistenza commerciale
                </div>
              </div>
            </div>}

           
          </div>
        </div>
      );
      break;

    case "form":
      component = (
        <div className="form-container">
            <div className="heading" style={{ textAlign: "center" }}>
          Scopri i Voucher
        </div>
            <div className="subheading" style={{ textAlign: "center" }}>
          Ti chiamiamo <b>gratis </b>e <b>senza impegno</b>.
        </div>
        <br/>
          <Form />
          <div onClick={() => setComponentState("A")}>
            <br />
            <br />
            <u style={{cursor: 'pointer'}}>Torna ai voucher</u>
          </div>
        </div>
      )
      heading =<></>
      subheading =<></>
      select = <></>;

    default:
      break;
  }

  return (
    <div className="app">
      <div className="description">
        {heading}
        {subheading}
        {select}
      </div>

      <div className="content">{component}</div>
    </div>
  );
}

export default TimBusiness;
