import "./servizi.css";
import { GlobalContext } from "../../contexts/globalContext";
import React, { useState, useContext } from "react";
import luce from './../../img/gas/light.svg'
import gas from './../../img/gas/gas.svg'


function Servizi() {
  const { ContextSetState } = useContext(GlobalContext);
  function handleClick() {
    ContextSetState("step3");
  }
  return (
    <div className="service-parts">
      <div className="service-part" onClick={() => handleClick()}>
        <img src={luce} alt="luce" />
        <div className="service-text">Luce</div>
      </div>

      <div className="service-part" onClick={() => handleClick()}>
        <img src={gas} alt="gas" />
        <div className="service-text">Gas</div>
      </div>

      <div className="service-part" onClick={() => handleClick()}>
        <img src={luce} alt="luce" />
        <p className="plus">+</p>
        <img src={gas} alt="gas" />
        <div className="service-text">Luce + Gas</div>
      </div>

    </div>
  );
}

export default Servizi;
