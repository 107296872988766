import "./day.css";
import morning from "../../img/gas/day/morning.svg";
import afternoon from "../../img/gas/day/afternoon.svg";
import evening from "../../img/gas/day/evening.svg";
import night from "../../img/gas/day/night.svg";
import { GlobalContext } from "../../contexts/globalContext";
import React, { useState, useContext } from "react";

function Day() {
  const { ContextSetState } = useContext(GlobalContext);
  function handleClick() {
    ContextSetState("step5");
  }
  return (
    <div className="day-parts">
      <div className="day-part" onClick={() => handleClick()}>
        <img src={afternoon} alt="morning" />
        <div className="day-text">Mattino</div>
      </div>

      <div className="day-part" onClick={() => handleClick()}>
        <img src={night} alt="evening" />
        <div className="day-text">Sera</div>
      </div>
    </div>
  );
}

export default Day;
